.nosotros{
    span {
        font-weight: 600;
    }
    h2 {
        font-size: 2.4rem;
        text-transform: uppercase;
        
        @include tablet {
            font-size: 2.8rem
        }
    }

    .sobre-nosotros {
        padding: 1rem;
        background-color: $gris;
        -webkit-box-shadow: 1px 1px 11px 0px rgba(50, 50, 50, 0.75);
        -moz-box-shadow: 1px 1px 11px 0px rgba(50, 50, 50, 0.75);
        box-shadow: 1px 1px 11px 0px rgba(50, 50, 50, 0.75);
        margin-bottom: 2rem;


        .nosotros-texto {
            padding: 1rem;
            line-height: 2;

            p {
                font-size: 1.8rem;

                @include tablet {
                    font-size: 2rem;
                }
            }

        }

        @include tablet {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 2rem;
            padding: 1rem;
            align-items: center;
            text-align: justify;
        }
    }
}