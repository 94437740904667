/**Telefonos y Redes**/
.contacto {
    max-width: 900px;
    margin: 0 auto;
    padding: 1rem;

    h2 {
        font-size: 2.8rem;
        
    }
}

.contacto-redes {
    padding: 2rem;
    background-color: white;
    border-radius: 10px;
    -webkit-box-shadow: 1px 1px 11px 0px rgba(50, 50, 50, 0.75);
    -moz-box-shadow: 1px 1px 11px 0px rgba(50, 50, 50, 0.75);
    box-shadow: 1px 1px 11px 0px rgba(50, 50, 50, 0.75);
    margin-bottom: 2rem;
    margin-top: 2rem;
    text-align: center;

    @media(max-width: 768px) {
        padding: 1.5rem 2rem;
    }

    h3 {
        margin-top: 2rem;
        @media(max-width: 768px) {
            font-size: 1.8rem;
        }
    }

    .ubi-contacto {
        h2 {
            font-size: 2.4rem;
            text-transform: uppercase;

            @include tablet {
                font-size: 2.8rem
            }
        }

        // .ubi {
        //     padding: 1rem;
        //     margin-bottom: 2rem;

        //     @include tablet {
        //         display: grid;
        //         grid-template-columns: repeat(2, 1fr);
        //         column-gap: 2rem;
        //         padding: 1rem;
        //         justify-content: center;
        //         align-items: center;
        //     }

        // }
    }

    .ubi-maps {
        .maps {
            max-width: 100%;
        }
    }

    .ubi-text {
        p {
            text-align: center;
            font-size: 1.8rem;

            @include tablet {
                font-size: 2rem;

            }
        }
    }

}

.redes-contacto {
    i {
        color: white;
        font-size: 5rem;
    }

    p {
        color: white;
        font-size: 3;
    }

    @media(min-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 600px;
        margin: 0 auto;

    }
    button {
        margin-top: 1rem;
    }

    .face {
        padding: 1.8rem;
        background-color: $face;
        cursor: pointer;
        color: white;
        border: none;
        border-radius: 5px;

        &:hover {
            background-color: darken($face, 10);
        }

        @media(min-width: 768px) {
            padding: 1.8rem 2.8rem;
        }
    }

    .insta {
        padding: 1.8rem;
        background-image: linear-gradient(-225deg, #231557 0%, #44107A 29%, #FF1361 67%, #FFF800 100%);
        cursor: pointer;
        color: white;
        border: none;
        border-radius: 5px;

        &:hover {
            background-image: linear-gradient(-225deg, #1e1247 0%, #330b5e 29%, #ad1246 67%, #d4ce10 100%);
        }

        @media(min-width: 768px) {
            padding: 1.8rem 2.8rem;
        }
    }

    .wa {
        padding: 1.8rem;
        background-color: $wa;
        cursor: pointer;
        color: white;
        border: none;
        border-radius: 5px;

        &:hover {
            background-color: darken($wa, 10);
        }

        @media(min-width: 768px) {
            padding: 1.8rem 2.8rem;
        }
    }

}

.numero-contacto {
    padding: 1rem;
    display: block;
    justify-content: center;
    font-size: 1.8rem;
    align-items: center;
    background-color: $gris;

    a {
        color: black;
        margin-top: 5px;
    }

    @media(min-width: 768px) {
        display: flex;
        font-size: 3rem;
    }
}
.email-contacto {
    padding-bottom: 1rem;
    display: block;
    justify-content: center;
    font-size: 2.5rem;
    align-items: center;
    background-color: $gris;
    
    
    p {
        font-size: 1.8rem;
        margin: 0 5px 2px 3px;
    }
    
    a {
        color: black;
    }
    
    @media(min-width: 768px) {
        display: flex;
        font-size: 3rem;

        p {
            font-size: 3rem;
            margin-right: 10px;
        }
    }
}
.icono-contacto i {
    color: $magenta;
}

/** Contacto **/
.contacto-contenedor {
    margin-bottom: 2rem;

    h2 {
        text-transform: uppercase;
        font-size: 2.4rem;

        @include tablet {
            font-size: 2.8rem;
        }
    }

    p {
        text-align: justify;
        font-size: 1.8rem;

        @include tablet {
            font-size: 2rem;
        }
    }
}



.formulario {
    background-color: $gris;
    padding: 1rem 2rem;
    border-radius: 10px;
    scroll-snap-align: center;
    scroll-snap-stop: always;

    @include tablet {
        width: 80%;
        padding: 1rem;
        -webkit-box-shadow: 1px 1px 11px 0px rgba(50, 50, 50, 0.75);
        -moz-box-shadow: 1px 1px 11px 0px rgba(50, 50, 50, 0.75);
        box-shadow: 1px 1px 11px 0px rgba(50, 50, 50, 0.75);
        max-width: 800px;
        width: 80%;
        padding: 2rem 3rem;
        margin: 0 auto;
    }

    h2 {
        text-align: center;
        margin-bottom: 2rem;
        display: block;
        text-transform: uppercase;
        font-weight: 700;
    }
}

.contenedor-campos {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.campo {
    margin-bottom: 1rem;
    width: 100%;

    @include telefono {
        display: flex;
        align-items: center;
    }

    @include tablet {
        flex: 0 0 calc(50% - .5rem)
    }

    label {
        color: var(--blanco);
        font-weight: 700;
        margin-bottom: .5rem;
        display: block;

        @include telefono {
            flex: 0 0 90px;
        }
    }
}


.campo input[type="text"],
.campo input[type="tel"],
.campo input[type="mail"],
.campo textarea {
    width: 100%;
    padding: .5rem;
    border: none;
    border-radius: .5rem;
}

@media (min-width: 768px) {

    :is(.campo) :is(input[type="text"],
        input[type="text"],
        input[type="tel"],
        input[type="mail"],
        textarea) {
        flex: 1;
    }
}

.w-100 {
    flex: 0 0 100%;
}

.campo textarea {
    height: 7rem;
}