.footer {
    padding: 2.5rem;
    background-color: $gris;
    text-align: center;
    margin: 0;
    color: $grisos;



    .redes {
        padding: 2rem;
        font-size: 3rem;
        @media(min-width: 768px) {
            font-size: 4rem;
        }

        a {
            padding: 1rem;
            color: $grisos;
        }

        :hover {
            color: $magenta;
        }
    }

    .tel {
        font-size: 3rem;
        @media(min-width: 768px){
            padding: 2rem;
            font-size: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        a {
            text-decoration: none;
            color: black;
            @media(max-width: 768px) {
                font-size: 3rem;
                margin: 0 0 0 2rem;
                
            }
        }
    }

}
