// Fuentes
$fuente_principal: 'Poppins',
sans-serif;


// Tamaño de Media Queries
$telefono: 480px;
$tablet: 1010px;
$desktop: 1024px;

// Colores
$cian: #00B0F6;
$magenta: #F50087;
$crema: #FFFDD0;
$blanco: white;
$negro: black;
$gris: #F5F5F5;
$grisos: #505050;
$yellow: #FFF755;
$insta: #ff0081;
$face: #258EF1;
$wa: #49D754;


//Separacion
$separacion: 5rem;

// Fuentes

$delgada: 300;
$regular : 400;
$bold: 600;
$black: 900;