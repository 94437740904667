.galeria{

    h3{
        text-align: center;
    }
}
.galeria-imagenes{
    padding: 0;
    list-style: none;
    @include grid(1, $separacion);
    @include tablet{
        @include grid(4, $separacion / 2);
    }
    img{
        border: 1px solid;
    }
}