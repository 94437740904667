.pagos {
    // padding: .5rem;
    margin: 0;
    justify-content: center;

    h3 {
        font-weight: $bold;
        font-size: 1.8rem;
        color: $negro;
    }

    @include tablet {
        h3 {
            font-size: 2rem;
        }

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.5rem;
        margin: 0;
    }
}

.enlaces {
    display: flex;
    align-items: center;
    justify-content: center;

    .pse {
        width: 40px;
        height: 40px;
    }

    .aval {
        width: 120px;
        height: 80px;
    }

    @include tablet {
        .pse {
            margin: 0 2rem;
            width: 80px;
            height: 80px;
        }

        .aval {
            width: 220px;
            height: 120px;
        }
    }
}