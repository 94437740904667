.contenedor-estetica {
  max-width: 92%;
  margin: 0 auto;
  background-color: $gris;
  padding: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  h2 {
    font-size: 2.5rem;
    @include tablet {
      font-size: 2.8rem;
    }
  }
  p {
    text-align: justify;
    font-size: 1.8rem;
    @include tablet {
      font-size: 2.4;
    }
  }
  span {
    font-weight: $bold;
  }
}

.app {
  width: 90%;
  margin: 0 auto;
  padding-top: $separacion / 2;
  text-align: center;

  .seccion {
    display: none;
    margin-top: $separacion / 2;
  }
  p {
    text-align: center;
    font-size: 3rem;
  }
}

.mostrar-seccion {
  display: block !important;
}
