.tabs {
    display: none;

   

    button {
        background-color: $cian;
        font-size: 1.8rem;
        font-weight: $bold;
        padding: 1rem;
        text-align: center;
        text-transform: uppercase;
        border: none;
        color: $blanco;
        margin-top: 1rem;

        @include tablet {
            margin-top: 0;
        }

        &.actual {
            background-color: $yellow;
            color: $negro;
        }

        &:hover {
            cursor: pointer;
        }
    }

    @include tablet {
        @include grid(5, 2rem);
    }

}
.mobile-menu {
    @media (min-width: 1000px) {
        display: none;
    }

    img {
        width: 5rem;
    }
}
.mostrar {
    display: flex;
    flex-direction: column;
}