.ubicacion-seccion{
    h2 {
        font-size: 2.4rem;
        text-transform: uppercase;
        
        @include tablet {
            font-size: 2.8rem
        }
    }
    .ubicacion{
        padding: 1rem;
        background-color: $gris;
        -webkit-box-shadow: 1px 1px 11px 0px rgba(50, 50, 50, 0.75);
        -moz-box-shadow: 1px 1px 11px 0px rgba(50, 50, 50, 0.75);
        box-shadow: 1px 1px 11px 0px rgba(50, 50, 50, 0.75);
        margin-bottom: 2rem;
        @include tablet {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 2rem;
            padding: 1rem;
            justify-content: center;
            align-items: center;
        }
        
    }
}
.ubicacion-maps{
    .maps{
        max-width: 100%;
    }
}
.ubicacion-texto{
    p{
        text-align: center;
        font-size: 2.5rem;
        @include tablet{
            font-size: 4rem;
            
        }
    }
}
