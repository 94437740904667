.boton-productos{
    background-color: $yellow;
    color: $negro;
    font-weight: $bold;
    text-decoration: none;
    padding: 1rem 3rem;
    text-align: center;
    margin-top: $separacion / 2;
    display: flex;
    border: none;
    text-align: center;
    justify-content: center;

    &:hover {
        background-color: darken($cian, 1);
        cursor: pointer;
    }
}
.boton-contacto{
    background-color: $cian;
    color: $blanco;
    font-weight: $bold;
    text-decoration: none;
    padding: 1rem 3rem;
    text-align: center;
    margin-top: $separacion / 2;
    display: flex;
    border: none;
    text-align: center;
    justify-content: center;
    &:hover {
        background-color: darken($cian, 10);
        cursor: pointer;
    }
}