.video {
    position: relative;
    max-height: 50rem;
    overflow: hidden;

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00b0f6+0,f50087+31,ffe900+68,ffe900+68,000000+100&0.2+0,0.31+100 */
        background: -moz-linear-gradient(left, rgba(0, 176, 246, 0.2) 0%, rgba(245, 0, 135, 0.23) 31%, rgba(255, 233, 0, 0.27) 68%, rgba(0, 0, 0, 0.31) 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(0, 176, 246, 0.2) 0%, rgba(245, 0, 135, 0.23) 31%, rgba(255, 233, 0, 0.27) 68%, rgba(0, 0, 0, 0.31) 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(0, 176, 246, 0.2) 0%, rgba(245, 0, 135, 0.23) 31%, rgba(255, 233, 0, 0.27) 68%, rgba(0, 0, 0, 0.31) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3300b0f6', endColorstr='#4f000000', GradientType=1);
        /* IE6-9 */
    }

    video {
        display: block;
        width: 100%;
    }
}

.contenido-video {
    display: flex;
    align-items: center;
    height: 100%;

    h2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: $blanco;
        font-size: 2rem;
        font-weight: $bold;

        span {
            background-color: $yellow;
            font-size: 1.5rem;
            padding: 1rem 2rem;
            color: black;
        }
    }

    @include tablet {
        h2 {
            font-size: 5rem;

            span {
                font-size: 2.5rem;
                padding: 1rem 2rem;
            }
        }
    }
}