.productos {
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: $gris;
    padding: 1rem;
    -webkit-box-shadow: 1px 1px 11px 0px rgba(50, 50, 50, 0.75);
    -moz-box-shadow: 1px 1px 11px 0px rgba(50, 50, 50, 0.75);
    box-shadow: 1px 1px 11px 0px rgba(50, 50, 50, 0.75);

    h2 {
        text-transform: uppercase;
        font-size: 2.4rem;

        @include tablet {
            font-size: 2.8rem
        }
    }

    @include tablet {
        .contenedor-anuncios {
            @include grid(2, 2rem);
            max-width: 66.6%;
            margin: 0 auto;
            gap: 4rem;
        }
    }

    .anuncio {
        background-color: lighten(#e2e2e2, 5);
        border: 1px solid darken($blanco, 10);
        margin-bottom: 1rem;
        -webkit-box-shadow: 1px 1px 11px 0px rgba(50, 50, 50, 0.75);
        -moz-box-shadow: 1px 1px 11px 0px rgba(50, 50, 50, 0.75);
        box-shadow: 1px 1px 11px 0px rgba(50, 50, 50, 0.75);

        .contenido-anuncio {
            padding: 1rem;
        }

        h3 {
            font-size: 2rem;

            @include tablet {
                font-size: 2.2rem;
            }
        }

    }

    img {
        width: 420px;
        height: auto;

        @include tablet {
            width: auto;
            height: auto;
        }
    }
}