html {
    font-size: 62.5%;
    box-sizing: border-box;
    min-height: 100%;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    font-family: $fuente_principal;
    font-size: 1.6rem;
    background-image: url('/build/img/bg.png');
    background-attachment: fixed;
    background-size: cover;
    margin: 0;
}

p {
    font-size: 2rem;
}

.contenedor {
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
}

a {
    text-decoration: none;
}

img {
    max-width: 100%;
}

h1,
h2,
h3 {
    font-family: $fuente_principal;
    font-weight: 900;
    text-align: center;
}

h1 {
    font-size: 4rem;
}

h2 {
    font-size: 3.6rem;
}

h3 {
    font-size: 3rem;
    text-align: center;
}
footer{
    position: relative;
    bottom: 0;
    width: 100%;
}