.header {
  background-color: $blanco;
  border-bottom: 1px solid $cian;

  .contenido-header {
    padding: 1rem;

    @include tablet {
      padding: 1rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .nav-secundaria {
      display: flex;
      gap: 1.5rem;
      justify-content: center;
      text-align: center;

      @include tablet {
        gap: 5rem;
      }

      a {
        color: $grisos;
        font-size: 1.5rem;
        text-transform: uppercase;
        margin: 1.5rem 1rem;
        font-weight: $black;

        &:hover {
          color: $cian;
        }

        @include tablet {
          font-size: 2.5rem;
        }
      }
    }
  }
}

.logo {
  width: 180px;
  height: 101px;
  margin: 0 auto;

  @include tablet {
    margin: 0 0;
    width: 260px;
    height: auto;
  }
}

.navegacion {
  background-color: $gris;
  border-bottom: 1px solid $cian;
  border-top: 1px solid $cian;
  

  
}
.navegacion-principal {
  display: flex;
  justify-content: center;
  text-align: center;
  
  @include tablet {
    gap: 5rem;
  }

  a {
    color: $grisos;
    font-size: 1.8rem;
    margin: 0;
    text-transform: uppercase;
    font-weight: $bold;
    padding: 3rem 1rem;

    &:hover {
      color: white;
      background-color: $cian;
    }

    @include tablet {
      padding: 3rem 4rem;
      font-size: 2.5rem;
    }
  }
}